@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("node-source-han-sans/SourceHanSans-Regular-all.css");

/* Menu Team */
.menu-team {
  height: fit-content;
  width: 18.75rem;
  background: #ffffff;
  border-radius: 0.625rem;
  opacity: 1;
  padding: 1.25rem;
  letter-spacing: 0;
  color: #2c2c2c;
  box-sizing: border-box;
  margin-right: 1.25rem !important;
}

.team-btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  background-color: #ffffff !important;
  margin-left: 0;
  justify-content: left !important;
  font: bold 0.9375rem/1.3125rem Noto Sans JP !important;
}

.btn-add-team {
  background: #00ba0f !important;
  font: bold 0.6875rem/1rem Noto Sans JP !important;
  color: #ffffff !important;
  border-radius: 0.9375rem !important;
  width: 6.625rem;
  height: 1.875rem !important;
  padding: 0.375rem 1.25rem;
  margin-bottom: 1.25rem;
}

.team-action-item {
  text-align: left;
  font: normal normal bold 0.875rem/1.25rem Source Han Sans;
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.first-team-action-item {
  border-radius: 0.5rem 0.5rem 0 0;
}

.delete-item {
  color: #ff4b45;
  border-radius: 0 0 0.5rem 0.5rem;
}

.menu-team .table-th {
  font: bold 0.75rem/1.0625rem Noto Sans JP !important;
}

.menu-team .col-emp {
  font: bold 0.9375rem/1.3125rem Noto Sans JP !important;
  color: #a5a5a5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: left !important;
}

.team-action {
  position: absolute;
  top: -2.5rem;
  left: 2.5rem;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-radius: 1rem !important;
  box-sizing: border-box;
  box-shadow: 0.1rem 0.1rem 0.6rem 0rem #dad7d7 !important;
}
/* Modal add team */

.modal-add-team {
  top: 35vh;
  min-height: 15.4375rem !important;
  min-width: 31.25rem !important;
  background: #ffffff;
  border-radius: 10px;
  opacity: 1;
  margin: 0 !important;
  font: normal normal bold 1rem/1.5rem Noto Sans JP !important;
}

.button-cancel-modal {
  width: 80px;
  height: 24px;
  text-align: left;
  letter-spacing: 0px;
  color: #666666 !important;
  opacity: 1;
  padding: 0;
  background-color: #ffffff !important;
}

.title-modal {
  width: 96px;
  height: 24px;
  text-align: center;
  font: normal normal bold 1rem/1.5rem Noto Sans JP !important;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  margin-left: 5.6875rem;
}

/* Team Information */
.tab-button-1 {
  background-color: #00ba0f !important;
  width: 10rem;
  height: 1.875rem !important;
  border-radius: 0.9375rem !important;
  color: #ffffff !important;
  font: normal normal bold 0.6875rem/1rem Noto Sans JP !important;
  letter-spacing: 0;
  opacity: 1;
}

.tab-button-2 {
  background-color: #404040 !important;
  width: 10rem;
  height: 1.875rem !important;
  border-radius: 0.9375rem !important;
  color: #ffffff !important;
  font: normal normal bold 0.6875rem/1rem Noto Sans JP !important;
  letter-spacing: 0;
  opacity: 1;
}

.dropdown-item {
  min-width: 9.375rem !important;
  font-size: 0.75rem;
  padding: 0;
  position: absolute;
  top: -0.5rem;
  box-shadow: 0.1rem 0.1rem 0.6rem 0rem #dad7d7 !important;
  border-radius: 0.625rem !important;
}

.first-menu-item {
  border-radius: 0.625rem 0.625rem 0 0;
}

.last-menu-item {
  border-radius: 0 0 0.625rem 0.625rem;
}

.overall-info {
  margin-top: 1.25rem;
  height: 12.125rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
  opacity: 1;
  padding: 0.8125rem 1.875rem 1.3125rem 1.875rem;
}

.overall-team-name {
  width: 4.0625rem;
  height: 1.625rem;
  text-align: left;
  font: normal normal 800 1.125rem/1.625rem Noto Sans JP;
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
  margin: 0;
}

.team-status {
  width: 100%;
  height: 6.5625rem;
  opacity: 1;
}

.status-title {
  text-align: left;
  font: normal normal bold 0.75rem/1.0625rem Source Han Sans !important;
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.status-data {
  text-align: left;
  font: normal normal bold 1.1875rem/1.5625rem Roboto !important;
  letter-spacing: -0.059375rem;
  color: #2c2c2c;
  opacity: 1;
}

.status-addition-info {
  text-align: left;
  font: normal normal bold 0.6875rem/1rem Source Han Sans JP;
  letter-spacing: 0;
  color: #a5a5a5;
  opacity: 1;
}

@media (min-width: 1600px) {
  .grid-container {
    grid-template-columns: 15% 12% 12% 12% 15% 15%;
  }
}

@media (max-width: 1600px) {
  .grid-container {
    grid-template-columns: 16% 15% 15% 15% 16% 18%;
  }
}

@media (min-width: 1600px) {
  .grid-container-member {
    grid-template-columns: 16% 15% 15% 15% 16% 17%;
  }
}

@media (max-width: 1600px) {
  .dropdown-item {
    top: -0.7rem;
  }

  .title-modal {
    margin-left: 2.6875rem;
  }

  .grid-container-member {
    grid-template-columns: 17% 16% 16% 16% 17% 18%;
  }
}
