@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@import url("node-source-han-sans/SourceHanSans-Regular-all.css");

body {
  background-color: #ededed !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

@media screen and (min-width: 1920px) {
  .box-border-right {
    margin-right: 1.875rem;
  }
}

@media screen and (max-width: 1920px) {
  html {
    font-size: 16px;
  }

  .sort-by-info .second-sort-list,
  .sort-by-info .first-sort-list {
    position: absolute;
    top: -0.5rem;
  }

  .box-border-right {
    margin-right: 1.875rem;
  }
}

@media screen and (max-width: 1850px) {
  html {
    font-size: 15.5px;
  }
}

@media screen and (max-width: 1750px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 1630px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1550px) {
  html {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1430px) {
  html {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 12px;
  }

  .sort-by-info .second-sort-list,
  .sort-by-info .first-sort-list {
    position: absolute;
    top: -0.7rem;
  }

  .box-border-right {
    margin-right: 1rem;
  }

  .time-format-text {
    text-align: center;
    font: normal normal normal 1rem/1.25rem Roboto !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

@media screen and (max-width: 1320px) {
  html {
    font-size: 11.5px;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 11px;
  }
}

@media screen and (max-width: 1220px) {
  html {
    font-size: 10.5px;
  }
}

@media screen and (max-width: 1160px) {
  html {
    font-size: 10px;
  }
}
@media screen and (max-width: 1110px) {
  html {
    font-size: 9.5px;
  }
}
@media screen and (max-width: 1060px) {
  html {
    font-size: 9px;
  }
}

.react-datepicker-wrapper {
  width: 11.625rem;
  height: 1.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #d5d5d5;
  border-radius: 0.375rem;
  opacity: 1;
}

.page-title {
  font: normal normal 800 1.3125rem/1.875rem Noto Sans JP;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
}

.custom-datepicker-input {
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal 550 0.8125rem/1.1875rem Source Han Sans;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 0.625rem;
}

.member-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
  opacity: 1;
}

.today-date-picker {
  width: 3.125rem;
  height: 1.75rem !important;
  background: #404040 0% 0% no-repeat padding-box !important;
  border-radius: 0.375rem !important;
  opacity: 1;
  margin-left: 1rem;
}

.today-date-picker p {
  width: 1.375rem;
  height: 1rem;
  text-align: center;
  font: normal normal bold 0.6875rem/1rem "Noto Sans JP";
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.sort-by-info .sort-title {
  width: 2.75rem;
  height: 1rem;
  font: normal normal bold 0.6875rem/1rem "Source Han Sans";
  letter-spacing: 0;
  color: #919191;
  text-align: left;
  opacity: 1;
}

.sort-by-info .first-sort {
  width: 10.5rem;
  height: 1.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b1b1b1;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0 0.625rem 0 0.5625rem;
}

.sort-by-info .first-sort .selected_item {
  text-align: left;
  font: normal normal bold 0.6875rem/1rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.sort-by-info .first-sort-list {
  width: 10.5rem;
  text-align: left;
  font: normal normal bold 0.75rem/1.0625rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 10.5rem;
  box-shadow: 0.1rem 0.1rem 0.6rem 0rem #dad7d7 !important;
}

.sort-by-info .first-sort-item {
  text-align: left;
  font: normal normal bold 0.75rem/1.0625rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.team-name-title {
  height: 1.625rem;
  font: normal normal 800 1.125rem/1.625rem "Noto Sans JP";
  letter-spacing: 0;
  color: #2c2c2c;
  text-align: left;
  opacity: 1;
  width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sort-by-info .second-sort {
  width: 4.375rem;
  height: 1.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b1b1b1;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0 0.625rem 0 0.5625rem;
}

.sort-by-info .second-sort .selected-sort {
  text-align: left;
  font: normal normal bold 0.6875rem/1rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.sort-by-info .second-sort-list {
  width: 4.375rem;
  text-align: left;
  font: normal normal bold 0.75rem/1.0625rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 4.375rem;
  box-shadow: 0.1rem 0.1rem 0.6rem 0rem #dad7d7 !important;
}

.sort-by-info .second-sort-item {
  text-align: left;
  font: normal normal bold 0.75rem/1.0625rem "Source Han Sans";
  letter-spacing: 0;
  color: #2c2c2c;
  opacity: 1;
}

.filter-status-button {
  width: 10rem;
  height: 1.875rem !important;
  background: #404040 0% 0% no-repeat padding-box !important;
  border-radius: 0.9375rem !important;
  opacity: 1;
}

.filter-status-text {
  font: normal normal bold 0.6875rem/1rem "Noto Sans JP";
  letter-spacing: 0;
  color: #ffffff;
  text-align: center;
  opacity: 1;
}

.time-format {
  width: 4.6875rem;
  font: normal normal bold 0.9375rem/1.0625rem "Noto Sans JP";
  letter-spacing: 0;
  color: #919191;
  text-align: left;
  opacity: 0.3;
}

.time-format-text {
  text-align: center;
  font: normal normal normal 1rem/1.3125rem Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.time-format-24h {
  width: 5.125rem;
  height: 1.875rem !important;
  background: #00ba0f 0% 0% no-repeat padding-box !important;
  border-radius: 0.9375rem !important;
  opacity: 1;
}

.time-format-12h {
  width: 5.125rem;
  height: 1.875rem !important;
  background: #404040 0% 0% no-repeat padding-box !important;
  border-radius: 0.9375rem !important;
  opacity: 1;
}

.react-datepicker__day--today {
  font-weight: 800;
}
